import { template as template_93322ba2a8d648519cbc5079bef95dde } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_93322ba2a8d648519cbc5079bef95dde(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
