import { template as template_cd5104b45be14a369db353c94dd8b71c } from "@ember/template-compiler";
const FKLabel = template_cd5104b45be14a369db353c94dd8b71c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
