import { template as template_b710d1e5812b43db88585de18b496e72 } from "@ember/template-compiler";
const WelcomeHeader = template_b710d1e5812b43db88585de18b496e72(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
