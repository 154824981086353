import { template as template_c151125a5ad74ac9a718387675e561c1 } from "@ember/template-compiler";
const FKControlMenuContainer = template_c151125a5ad74ac9a718387675e561c1(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
